import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "~/components/Section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <h1>
        <FormattedMessage id="404_title" />
      </h1>
      <p>
        <FormattedMessage id="404_description" />
      </p>
    </Section>
  </Layout>
)

export default NotFoundPage
